import {Pipe, PipeTransform} from '@angular/core';
import {JSONPath} from 'jsonpath-plus'
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

    transform(value: any): any {
        console.log("Pipe works ", typeof value);
        return typeof value;
    }

}

@Pipe({
    name: 'productVariantNames'
})
export class ProductVariantNames implements PipeTransform{

    transform(value: any): any {
        let returnValueUnfiltered = JSONPath({path: '$..varname', json: value});
        let filteredSet = new Set(returnValueUnfiltered);
        this.getPipes(value);
        console.log("Returning: " + JSON.stringify([...filteredSet]));
        return [...filteredSet];
    }

    getPipes(objInput: any): any{
        Object.entries(objInput).forEach(([key, value]) => {
            console.log(`${key} ${value}`);
            console.log(typeof value);
            let keyName:string = '$['+key+']';
            console.log(JSONPath({path: '$['+key+']', json: objInput}));
        });

        return true;
    }
}

@Pipe({
    name: 'productVariantsList'
})
export class ProductVariantsList implements PipeTransform{

    transform(value: any): any {
        let valueToReturn = this.getVariants(value);
        //console.log("Before cleaning: " + JSON.stringify(valueToReturn));
        //console.log("To Return: ");
        //console.log(valueToReturn);

        return valueToReturn;
    }

    getVariants(value: any){
        let variablesList:any[] = [];
        //console.log("Value: " + JSON.stringify(value));

        value.forEach((element:any)=>{
            //console.log("Element: " + JSON.stringify(element));
            let objValue = <Array<string>> element.values;
            let newVariable = {id: element.id, varname: element.varname, values: objValue.join(";")}
            variablesList.push(newVariable);
        })
        return variablesList
    }
}
@Pipe({
    name: 'getVariationValues'
})
export class getVariationValues implements PipeTransform{

    transform(value: string): string[] {
        return value.split(";");
    }
}
@Pipe({
    name: 'jsonToArray'
})
export class JsonToArray implements PipeTransform{

    transform(value: any) {
        return value;
    }
}
@Pipe({
    name: 'stringDelimiter'
})
export class StringDelimiter implements PipeTransform{

    transform(value: string): any {
        return value.split('-');
    }
}

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(html:any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
