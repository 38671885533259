import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Posts} from "../Model/PostsModel"
import {environment} from "../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class PostsService {

    constructor(private HttpClient: HttpClient){}
	getHttpOptions(){
		let jwt = localStorage.getItem("MRFYJWT");
		if(!jwt) jwt="No Cookie Found";
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: jwt
			})
		}
		return httpOptions;
	}
    async createNewPost(posts:Posts){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.post<Posts>(environment.apiHost+environment.apiPath+"posts", posts, httpOptions)
                .subscribe(Response=>{
                    resolve(Response);
                })

        })
    }
    async editPosts(posts:Posts){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.put(environment.apiHost+environment.apiPath+"posts/"+posts.id, posts, httpOptions)
                .subscribe(Response=>{
                    resolve(Response);
                })
        })
    }
    async deletePost(postId:string){
        return new Promise(resolve => {
            this.HttpClient.delete(environment.apiHost+environment.apiPath+"posts/"+postId)
                .subscribe(Response=>{
                    resolve(Response);
                })
        })
    }
    async getAllPosts(page=1, filter={},  itemsPerPage=25):Promise<any>{
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.get<any>(environment.apiHost+environment.apiPath+'posts?filter='+JSON.stringify(filter)+'&size='+itemsPerPage+'&page='+page, httpOptions)
                .subscribe(Response => {
                    console.log(Response);
                    resolve(Response);
                })
        })
    }
    async getPostWithId(id:string){
        const httpOptions = this.getHttpOptions();
        return new Promise(resolve => {
            this.HttpClient.get<Posts>(environment.apiHost+environment.apiPath+'posts/'+id, httpOptions)
                .subscribe(Response =>{
                    console.log(JSON.stringify(Response));
                    resolve(Response)
                })
        })
    }

}
