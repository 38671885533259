import { Component, OnInit } from '@angular/core';

import $ from "jquery";
import {AuthService} from "../../../shared/auth.service";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  opened:boolean;
  children: RouteInfo[];
}
export const ROUTES: RouteInfo[] = [
  { path: '/admin/home', title: 'Pregledna Plošča',  icon: 'dashboard', class: '', opened: false, children: [] },
  { path: '/admin/products', title: 'Produkti',  icon:'sell', class: '', opened: false, children: []},
  { path: '', title: 'Naročila',  icon:'local_shipping', class: '', opened: true,children: [
      { path: '/admin/orders', title: 'Naročeno',  icon:'local_shipping', class: '', opened: false,children: []},
      { path: '/admin/shipments', title: 'Pošiljke',  icon:'local_shipping', class: '', opened: false,children: []}
    ]
  },
  { path: '/admin/tours', title: 'Koncerti',  icon:'live_tv', class: '', opened: false, children: [] },
  { path: '/admin/employees', title: 'Uporabniki',  icon:'people', class: '', opened: false, children: [] },
  { path: '/admin/posts', title: 'Objave', icon:'article', class:'', opened: false, children: [] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];

  constructor(private authService:AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log(this.menuItems);
  }
  isMobileMenu() {
    // @ts-ignore
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  openClose(menuItem:RouteInfo){
    menuItem.opened = !menuItem.opened;
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log(this.menuItems);

  }

  logout(){
    this.authService.logout();
  }
}
