import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {Customer} from "../Model/CustomerModel";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Buffer} from "buffer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router:Router, private HttpClient:HttpClient, private snackBar: MatSnackBar) { }

  public async isLoggedIn(){

    if(!environment.needsAuthentication) return true;
    if(localStorage.getItem("MRFYJWT") != null){
      try {
        // @ts-ignore
        let response = await this.validateAuthentication(localStorage.getItem("MRFYJWT"));
        return true;
      }catch(e:any){
        console.log(e);
        if(e.error == "Token Expired"){
          try {
            let refreshedToken:any = await this.refreshAuthorizationToken();
            localStorage.setItem("MRFYJWT", refreshedToken.accessToken);
            return true;
          }catch(refreshError){
            console.log("RefreshError: " + JSON.stringify(refreshError));
          }
        }
        return false;
      }

    }
    return false;
    //return (localStorage.getItem("MRFYJWT") != null)
  }

  async login(username:string, password:string)
  {
    let objectToSend = {email: username, password: password}
    try {
      let jwtToken = <string> await this.retrieveNewAuthorizatonToken(objectToSend)
      localStorage.setItem("MRFYJWT", jwtToken);
      this.router.navigate(['admin/home']);
      return {status: 200};
    }catch(error:any)
    {
      console.log("Error while authentication: " + JSON.stringify(error));
      if(error.status == 406)this.snackBar.open("Your username or password is not matching. Please try again!", "Close", {panelClass: ['red-snackbar']})
      else if(error.status == 0)this.snackBar.open("Authorization server did not respond to your request! Contact your administrator!", "Close", {panelClass: ['red-snackbar']})
      return error;
    }
  }

  async validateAuthentication(jwtToken:string){
    console.log("Sending credentials: " + jwtToken);
    return new Promise((resolve, reject) => {
      this.HttpClient.get<any>(environment.apiHost+environment.apiPath+"validate", {headers: new HttpHeaders({"mrfyToken": jwtToken})})
          .subscribe({next: response =>
            {
              resolve(true);
            },
            error: err =>{
              reject(err);
            }
          });
    });
  }

  async retrieveNewAuthorizatonToken(credentials:any){
    console.log("Sending credentials: " + JSON.stringify(credentials));
    return new Promise((resolve, reject) => {
        this.HttpClient.post<any>(environment.apiHost+environment.apiPath+"login", credentials, {headers: new HttpHeaders({"Access-Control-Allow-Origin": "http://localhost:3000"}), withCredentials: true})
            .subscribe({next: response =>
              {
                resolve(response);
              },
              error: err =>{
                reject(err);
              }
            });
    });
  }

  async refreshAuthorizationToken(){
    return new Promise((resolve, reject) => {
      this.HttpClient.post<any>(environment.apiHost+environment.apiPath+"refresh", {}, {headers: new HttpHeaders({"Access-Control-Allow-Origin": "http://localhost:3000"}), withCredentials: true})
          .subscribe({next: response =>
            {
              resolve(response);
            },
            error: err =>{
              reject(err);
            }
          });
    });
  }

  public logout(){
    localStorage.removeItem("MRFYJWT");
    this.router.navigate(['admin/home']);
  }
}
